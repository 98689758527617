<template>
    <div class="vehicles">
        <div class="vehicles__wrapper">
            <div class="vehicles__head">
                <h1 class="vehicles__title">
                    <span class="ml-1">Транспортные средства</span>
                </h1>
                <router-link v-if="!selectedByAdmin" class="vehicles__export" tag="a"
                             :to="{name:'ExportData'}"><i class="fas fa-file-download"></i></router-link>
            </div>

            <div class="vehicles__form" >
                <div class="vehicles__input-wrapper">
                    <input placeholder="Регистрационный номер" v-model="form.regnum" @input="regnum"
                           class="vehicles__input">
                    <small class="text-red-600"
                           v-if="!$v.form.regnum.required && $v.form.regnum.$dirty">{{
                            $t('forms.validation.required')
                        }}</small>
                </div>
                <div class="vehicles__input-wrapper">
                    <input placeholder="Марка" v-model="form.brand"
                           class="vehicles__input">
                    <small class="text-red-600"
                           v-if="!$v.form.brand.required && $v.form.brand.$dirty">{{
                            $t('forms.validation.required')
                        }}</small>
                </div>
                <div class="vehicles__input-wrapper">
                    <input placeholder="Модель" v-model="form.model"
                           class="vehicles__input">
                    <small class="text-red-600"
                           v-if="!$v.form.model.required && $v.form.model.$dirty">{{
                            $t('forms.validation.required')
                        }}</small>
                </div>
                <div class="vehicles__input-wrapper">
                    <select v-model="form.country" class="vehicles__input">
                        <option value="" selected disabled>Страна регистрации</option>
                        <option :value="country.code" :key="key" v-for="(country, key) in  countries ">
                            {{ country.name + ' - ' + country.code }}
                        </option>
                        <small class="text-red-600"
                               v-if="!$v.form.country.required && $v.form.country.$dirty">{{
                                $t('forms.validation.required')
                            }}</small>
                    </select>
                </div>

                <div class="sm:w-full xl:w-1/5">
                    <button class="vehicles__action vehicles__filter-action_color_deep" @click="add">
                        Добавить
                    </button>
                </div>

            </div>

            <div class="vehicles__filter-wrapper">
                <div class="vehicles__filter-content" v-for="(header,idx) in headers" :key="'header'+idx">
                    <select v-if="header.value === 'uf_country'" v-model="filter[header.value]"
                            class="vehicles__input">
                        <option value="" selected disabled>Страна регистрации</option>
                        <option :value="country.code" :key="key" v-for="(country, key) in  countries ">
                            {{ country.name + ' - ' + country.code }}
                        </option>
                    </select>
                    <input v-else :placeholder="header.name" v-model="filter[header.value]"
                           class="vehicles__input">
                </div>
                <div class="vehicles__filter-actions">
                    <button class="vehicles__action vehicles__filter-action_color_green" @click="filterItems">
                        Поиск
                    </button>
                </div>
                <div class="vehicles__filter-actions">
                    <button class="vehicles__action vehicles__filter-action_color_blue" @click="dropFilter">
                        Сбросить
                    </button>
                </div>
            </div>

            <div class="vehicles__table-wrapper" v-if="vehicles.length">
                <table class="vehicles__table">
                    <thead class="vehicles__table-headings">
                    <th class="vehicles__table-heading" @click="sort('id')">№
                        <span class="inline-block mx-1" :class="{
                            'visible':sorting.sort !== '' && sorting.by === 'id',
                            'visible transform  rotate-0':sorting.sort === 'desc' && sorting.by === 'id',
                            'visible transform   rotate-180':sorting.sort === 'asc' && sorting.by === 'id',
                            'hidden':sorting.by !== 'id'
                        }"><i class="fas fa-arrow-down "></i></span>
                    </th>
                    <th class="vehicles__table-heading" v-for="header in headers" :key="header.value"
                        @click="sort(header.value)">{{ header.name }}
                        <span class="inline-block  mx-1" :class="{
                            'visible':sorting.sort !== '' && sorting.by === header.value,
                            'visible transform rotate-0':sorting.sort === 'desc' && sorting.by === header.value,
                            'visible transform rotate-180':sorting.sort === 'asc' && sorting.by === header.value,
                            'hidden':sorting.by !== header.value
                        }"><i class="fas fa-arrow-down "></i></span>
                    </th>
                    <th class="p-3" v-if="!selectedByAdmin">Действие</th>

                    </thead>
                    <tbody class="vehicles__table-body">
                    <tr class="vehicles__table-row"
                        v-for="(car, idx) in vehicles" :key="car.id"
                    >
                        <td class="p-3">{{ idx + 1 }}</td>
                        <td class="p-3 " :key="header.value" v-for="header in headers">{{ car[header.value] }}</td>
                        <td class="p-3" v-if="!selectedByAdmin">
                            <button class="vehicles__delete "
                                    @click="remove(car.id)">
                                Удалить
                            </button>
                        </td>

                    </tr>
                    </tbody>
                </table>

            </div>
            <h3 class="vehicles__empty" v-if="!vehicles.length">
                Список транспортных средств пуст
                <g-emoji>😥</g-emoji>
            </h3>

            <Pagination :hideNext="hideNext" dashboard namespace="clientCars" v-if="vehicles.length"></Pagination>

        </div>

    </div>
</template>

<script>
import {mapGetters} from "vuex";
import {validationMixin} from 'vuelidate'
import {required} from 'vuelidate/lib/validators'
import TableSortingMixin from '@/mixins/tableSorting'
import Pagination from '@/components/common/Pagination'

export default {
    meta: {
        title: 'Транспорт'
    },
    name: "Cars",
    components: {Pagination},
    data: () => ({
        headers: [
            {name: 'Регистрационный номер', value: 'uf_regnum'},
            {name: 'Марка', value: 'uf_brand'},
            {name: 'Модель', value: 'uf_model'},
            {name: 'Страна регистрации', value: 'uf_country'},
        ],
        form: {
            model: '',
            brand: '',
            country: '',
            regnum: ''
        },
        filter: {
            uf_regnum: '',
            uf_brand: '',
            uf_model: '',
            uf_country: '',
        },
        user: {},
        selectedByAdmin: false
    }),
    beforeDestroy() {
        document.removeEventListener('keypress', this.pressEnter)
        this.$store.commit('clientCars/destroy')
    },
    mounted() {
        document.addEventListener('keypress', this.pressEnter)

        this.$watch('sorting', function (newVal) {
            this.$store.commit('clientCars/setSorting', {sort: newVal.sort, sortBy: newVal.by})
        }, {
            deep: true, immediate: true
        })
    },
    computed: {
        ...mapGetters({
            vehicles: 'clientCars/vehicles',
            countries: 'catalogs/countries',
            perPage: 'clientCars/perPage'
        }),
        hideNext() {
            return this.vehicles.length === 0 || this.vehicles.length < this.perPage;
        }
    },
    watch: {},
    methods: {
        pressEnter() {
            if (event.keyCode === 13) {
                this.filterItems()
            }
        },
        clear() {
            for (let i in this.form) {
                this.form[i] = ''
            }
            this.$v.$reset()
        },
        clearFilter() {
            for (let i in this.filter) {
                this.filter[i] = ''
            }
        },
        regnum(event) {
            let val = event.target.value
            val = val.replace(/[^0-9A-z]/g, '').toUpperCase()
            this.form.regnum = val
        },
        remove(id) {
            this.$store.dispatch('clientCars/removeVehicle', {id})
        },
        async add() {
            this.$v.$touch()
            if (!this.$v.$invalid) {
                await this.$store.dispatch('clientCars/addVehicle', this.form)
                this.clear()
            }
        },
        filterItems() {
            this.$store.commit('clientCars/setFilter', this.filter)
        },
        dropFilter() {
            this.$store.commit('clientCars/destroy')
            this.$store.dispatch('clientCars/getVehicles')
            this.clearFilter()
        },
    },
    mixins: [validationMixin, TableSortingMixin],
    validations: {
        form: {
            model: {required},
            brand: {required},
            country: {required},
            regnum: {required}
        }
    }
}
</script>

<style scoped>
.vehicles {
    @apply w-full mx-auto my-10
    xl:container
}

.vehicles__wrapper {
    @apply sm:my-10 xl:mx-10
}

.vehicles__head {
    @apply flex mb-5
}

.vehicles__export{
    @apply dark:text-yellow-300 mr-1 block text-blue-400 text-xl
}

.vehicles__title {
    @apply dark:text-white text-gray-800 text-2xl w-full flex justify-between
}

.vehicles__filter-wrapper {
    @apply flex mx-1 mb-5 h-full pt-3
    sm:flex-col
    xl:flex-row
}

.vehicles__filter-content {
    @apply sm:w-full  mr-0.5
    xl:w-1/6
}

.vehicles__form{
    @apply flex sm:flex-col mx-1 mb-5 h-full
    xl:flex-row
}
.vehicles__input-wrapper{
    @apply mr-0.5
    sm:w-full
    xl:w-1/5
}

.vehicles__input {
    @apply text-black placeholder-gray-600 w-full px-4 py-2.5  mt-2 text-base  dark:placeholder-gray-400
    transition duration-500 ease-in-out transform border-transparent
    bg-gray-200  focus:border-gray-500 focus:bg-white dark:bg-gray-700
    dark:focus:bg-gray-800 focus:outline-none dark:text-gray-300
    dark:focus:text-white
}

.vehicles__filter-actions {
    @apply mr-0.5
    sm:w-full
    xl:w-1/6
}

.vehicles__action {
    @apply px-4 py-2.5 mt-2 w-full text-white
}

.vehicles__filter-action_color_blue {
    @apply bg-blue-400
}

.vehicles__filter-action_color_green {
    @apply bg-green-400
}

.vehicles__filter-action_color_deep {
    @apply bg-blue-700
}

.dark .vehicles__table-wrapper {
    box-shadow: none;
}

.vehicles__table-wrapper {
    @apply overflow-x-scroll overflow-y-scroll max-h-128  shadow-md
}

.vehicles__table {
    @apply text-left w-full border-collapse  dark:text-gray-400  text-gray-700 border-separate space-y-6 text-sm
}

.vehicles__table-headings {
    @apply dark:bg-gray-800 bg-white text-gray-500
}

.vehicles__table-heading {
    @apply p-3 select-none
}

.vehicles__table-body {
    @apply dark:text-gray-400 text-gray-600
}

.vehicles__table-row {
    @apply dark:bg-gray-800 bg-white dark:hover:bg-gray-700 hover:bg-gray-300
}
.vehicles__delete{
    @apply cursor-pointer bg-red-600 text-center text-white py-1 px-4
}
.vehicles__empty {
    @apply text-2xl text-center w-full p-10;
}

::-webkit-scrollbar {
    display: block;
    overflow: auto;
    width: 5px;
    height: 5px;
}

::-webkit-scrollbar-thumb {
    background: lightskyblue;
}


select {
    -webkit-appearance: none;
}
</style>
